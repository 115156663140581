<template>
  <div class="help-center-topic" v-if="topic">
    <div class="help-center-topic--title">{{ topic.title }}</div>
    <div class="help-center-topic--content" v-html="topic.content"></div>
    <div class="help-center-topic--actions">
      <div class="help-center-topic--action-vote">
        <template v-if="userVoteThisTopic === false">
          <p class="mb20 important-text">{{ $t('wasTopicHelpful')}}</p>
          <simple-button
            class="mr20"
            :value="$t('yes')"
            :transparent="true"
            :disabled="userLikedThisTopic"
            @click="like"
          />
          <simple-button
            :value="$t('no')"
            :transparent="true"
            @click="dislike"
          />
        </template>
        <template v-else-if="userDislikedThisTopic && userSendQuestion === false">
          <p class="mb20 important-text">{{ $t('sorryAbout') }}</p>
          <InputWithLabel
            :label="$t('howImprove')"
          >
            <template #input>
            <textarea
              class="improve-text model-input base-textarea"
              name="text"
              :placeholder="$t('howImprove')"
              v-model="improveText"
            ></textarea>
            </template>
          </InputWithLabel>
          <simple-button
            :value="$t('submit')"
            @click="sendQuestion"
          />
        </template>
        <template v-else>
          <p class="important-text">
            {{ $t('thankYouFeedback') }}
          </p>
        </template>
      </div>
      <div class="help-center-topic--action-question">
        <simple-button
          :value="$t('noAnswer')"
          :transparent="true"
          @click="openQuestionModal"
        />
      </div>
    </div>

    <HelpCenterQuestionModal
      v-if="isQuestionModalVisible"
      @close="closeQuestionModal"
      @send="sendFeedback"
    />
    <SuccessModal
      :visible="isSuccessModalVisible"
      :title="$t('messageSend')"
      :description="$t('weReply')"
      @close="closeSuccessModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HelpCenterQuestionModal from '@/components/ui/modals/HelpCenterQuestionModal'
import InputWithLabel from '@/components/ui/forms/InputWithLabel'
import SuccessModal from '@/components/ui/modals/SuccessModal'

export default {
  name: 'HelpCenterTopic',
  components: {
    SuccessModal,
    InputWithLabel,
    HelpCenterQuestionModal,
  },
  data () {
    return {
      userLikedThisTopic: false,
      userDislikedThisTopic: false,
      userVoteThisTopic: false,
      userSendQuestion: false,
      improveText: '',
      showQuestionModal: false,
      showSuccessionModal: false,
    }
  },
  computed: {
    ...mapState({
      topic: state => state.helpCenter.topic,
      section: state => state.helpCenter.section,
      sections: state => state.helpCenter.sections,
      overlay: state => state.ui.overlay,
    }),
    slug () {
      return this.$route.params.topicSlug
    },
    isQuestionModalVisible () {
      return this.overlay && this.showQuestionModal
    },
    isSuccessModalVisible () {
      return this.overlay && this.showSuccessionModal
    },
  },
  async created () {
    await this.$store.dispatch('getHelpTopic', this.slug)

    this.userLikedThisTopic = this.topic.userLikedThisTopic

    if (!this.section) {
      this.sections.map(section => {
        const topicsIdsList = section.topics.map(topic => topic.id)
        if (topicsIdsList.includes(this.topic.id)) {
          this.$store.commit('selectSection', section)
        }
      })
    }
  },
  methods: {
    async like () {
      await this.$store.dispatch('likeTopic')
      this.userLikedThisTopic = true
      this.userVoteThisTopic = true
    },
    async dislike () {
      await this.$store.dispatch('dislikeTopic')
      this.userDislikedThisTopic = true
      this.userVoteThisTopic = true
    },
    openQuestionModal () {
      this.showQuestionModal = true
      this.$store.commit('showOverlay')
    },
    closeQuestionModal () {
      this.showQuestionModal = false
      this.$store.commit('hideOverlay')
    },
    async sendFeedback () {
      try {
        await this.$store.dispatch('sendFeedback')
        this.showQuestionModal = false
        this.showSuccessionModal = true
      } catch (error) {
        this.$store.commit('showHintNotification', {
          type: 'ERROR',
          title: error.message,
        })
      }
    },
    async sendQuestion () {
      try {
        await this.$store.dispatch('askQuestion', {
          topicId: this.topic.id,
          question: this.improveText,
        })
        this.userSendQuestion = true
      } catch (error) {
        this.$store.commit('showHintNotification', {
          type: 'ERROR',
          title: error.message,
        })
      }
    },
    closeSuccessModal () {
      this.showSuccessionModal = false
      this.$store.commit('hideOverlay')
    },
  },
  watch: {
    async slug () {
      await this.$store.dispatch('getHelpTopic', this.$route.params.topicSlug)

      this.sections.map(section => {
        const topicsIdsList = section.topics.map(topic => topic.id)
        if (topicsIdsList.includes(this.topic.id)) {
          this.$store.commit('selectSection', section)
        }
      })

      this.userLikedThisTopic = this.topic.userLikedThisTopic
      this.showQuestionModal = false
      this.showSuccessionModal = false
      this.userDislikedThisTopic = false
      this.userVoteThisTopic = false
      this.userSendQuestion = false
      this.improveText = ''
    },

    overlay (value) {
      if (!value) {
        this.showQuestionModal = value
        this.showSuccessionModal = value
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('setTopic', null)
    next()
  },
}
</script>

<style scoped lang="scss">
.help-center-topic {
  &--title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 18px;
    color: $color-primary;
  }
  &--content {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid $border-color;
  }

  &--actions {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-column-gap: 30px;
  }

  &--action-question {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
}
.mb20 {
  margin-bottom: 20px;
}
.mr20 {
  margin-right: 20px;
}
.improve-text {
  resize: none;
  height: 96px;
  padding: 15px;
}
</style>
